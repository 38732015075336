import React from 'react';
import { graphql, Link } from 'gatsby';
import Footer from '../components/footer';
import Grid from '@material-ui/core/Grid';
import LeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightIcon from '@material-ui/icons/KeyboardArrowRight';
import ItemIcon from '@material-ui/icons/ViewModule';
import { MD, PortfoWrapper } from '../components/global';
import '../pages/flexboxgrid.min.css';
import styled from 'styled-components';

const NavLink = styled(Link)`
  display: inline-block;
  vertical-align: middle;

  svg {
    vertical-align: middle;
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 24px;
    color: inherit;
    min-width: 0;
    padding: 0;
  }
`;

export default ({ data, locaiton, pageContext }) => {
  const { markdownRemark: post } = data;
  const { next, prev } = pageContext;
  return (
    <div>
      <PortfoWrapper>
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <Grid container spacing={8}>
          <Grid item xs={4}>
            {prev && (
              <NavLink to={prev.fields.slug}>
                <LeftIcon />
                <MD>Previous</MD>
              </NavLink>
            )}
          </Grid>
          <Grid item xs={4} className="text-center">
            <NavLink to="/#portfolio">
              <ItemIcon />
              <MD>Portfolio</MD>
            </NavLink>
          </Grid>
          <Grid item xs={4} className="text-right">
            {next && (
              <NavLink to={next.fields.slug}>
                <MD>Next</MD>
                <RightIcon />
              </NavLink>
            )}
          </Grid>
        </Grid>
      </PortfoWrapper>
      <Footer />
    </div>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
